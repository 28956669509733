import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import PublisherBillsRow from './PublisherBillsRow/PublisherBillsRow';
import {ROOT_DOMAIN_API} from "../../Constant";

function PublisherBills(props) {
  let { id } = useParams();
  const [billsList, setBillsList] = useState([]);

  useEffect(() => {
    if(!id){
      return;
    }

    (async () => {
        try {
            const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/publisherBills?q="+id);
            const obj = await resp.data;
            if(obj.status&&obj.results){
                setBillsList(obj.results);
            }
        } catch (error) {
            console.error(error);
        }
    })();
  }, [id]);

  return (
    <div className={"PublisherInformations ShadowContainer "+(props.childClass ? props.childClass : "")}>
        <h1 className="RegularTitle">Publisher's bills</h1>
        <table className={"Width100 "+(billsList.length<1?'Hide':'')}>
            <thead className="BBlue TWhite">
                <tr>
                    <th>Bill ID</th>
                    <th>Date</th>
                    <th>Payment date</th>
                    <th>Delay</th>
                    <th>Amount</th>
                    <th>Currency</th>
                    <th>Payment type</th>
                    <th>State</th>
                    <th>Payment type</th>
                    <th>State Name</th>
                    <th>Error</th>
                </tr>
            </thead>
            <tbody>
                {billsList.map((row, key) => {
                    return <PublisherBillsRow bill_id={row.bill_id}
                        key={key}
                        bill_date={row.bill_date} bill_amount={row.bill_amount}
                        bill_amount_dollar={row.bill_amount_dollar} bill_currency={row.bill_currency}
                        bill_exceptional={row.bill_exceptional}
                        bill_exceptional_dollar={row.bill_exceptional_dollar}
                        bill_exceptional_euro = {row.bill_exceptional_euro}
                        user_net30={row.bill_user_net30} paypal_mail={row.bill_user_paypal_mail}
                        payment_state={row.ptp_state} payment_state_name={row.payment_state_name}
                        payment_error={row.payment_error_message} payment_method_name={row.payment_method_name}
                        payment_method_libelle={row.payment_method_libelle}
                        />
                })}
            </tbody>
        </table>
        <p className={"TCenter "+(billsList.length<1?'':'Hide')}>There is no result to preview here.</p>
    </div>
  );
}
  
export default PublisherBills;
  