import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import LabelCardComponent from '../LabelCard/LabelCard';
import {ROOT_DOMAIN_API} from "../../Constant";
import { toast } from "react-toastify";

function PublisherInformations(props) {
  let { id } = useParams();
  const [userInformations, setUserInformations] = useState({});
  const [l, setL] = useState('');

  useEffect(() => {
    if(!id){
      return;
    }

    (async () => {
        try {
            const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/publisherDetails?q="+id);
            const obj = await resp.data;
            if(obj.status&&obj.results){
                let tmpObj = obj.results;
                for (let prop in tmpObj) {
                    if (tmpObj[prop] === '') {
                        tmpObj[prop] = null;
                    }
                }
                setUserInformations(tmpObj);
                setL('/');
            }
        } catch (error) {
            console.error(error);
        }
    })();
  }, [id]);

  const openViewImage = function(key){
    (async () => {
        try {
            const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/billing/billing_getImageContentByKey?key="+key);
            const obj = await resp.data;
            if(obj.status&&obj.results&&obj.results.body){
                openImg(obj.results.body);
            }
        } catch (error) {
            console.error(error);
        }
    })();
  }

    const openImg = function (base64ImageData) {
        var contentType = "image/jpeg";
        if (base64ImageData.includes("data:image/png;base64,")) {
            contentType = "image/png";
        }

        if (base64ImageData.includes("data:image/jpeg;base64,")) {
            contentType = "image/jpeg";
        }

        if (base64ImageData.includes("data:image/jpg;base64,")) {
            contentType = "image/jpg";
        }

        if (base64ImageData.includes("data:application/pdf;base64,")) {
            contentType = "application/pdf";
        }

        base64ImageData = base64ImageData.replace('data:image/png;base64,', '');
        base64ImageData = base64ImageData.replace('data:image/jpeg;base64,', '');
        base64ImageData = base64ImageData.replace('data:image/jpg;base64,', '');
        base64ImageData = base64ImageData.replace('data:application/pdf;base64,', '');

        const byteCharacters = atob(base64ImageData);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const slice = byteCharacters.slice(offset, offset + 1024);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        const blobUrl = URL.createObjectURL(blob);

        window.open(blobUrl, '_blank');
    }

    const getSiteIdForUserId = async () => {
        if(!id){
            return;
        }

        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_getSiteIdForUserId`, {
                params: { user_id: id },
            });

            if (response.data.status && response.data.results) {
                const siteId = response.data.results;
                handleMoneyboxAccess(siteId);
            } else {
                toast.error('Failed to connect to Moneybox', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error connecting to Moneybox:", error);
            toast.error('Error connecting to Moneybox', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleMoneyboxAccess = async (site_id) => {
        try {
            const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/bo_user/bo_moneyboxToken`, {
                params: { site_id: site_id },
            });

            if (response.data.status && response.data.results) {
                let url = "https://www.themoneytizer.com/new_manager/login&site_id=" + site_id + "&tbm_hash=" + response.data.results.tbm_hash
                window.open(url.toString(), '_blank');
            } else {
                toast.error('Failed to connect to Moneybox ', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (error) {
            console.error("Error adding site:", error);
            toast.error('Error adding site', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    const handleMoneyboxUserIdSubmit = () => {
        getSiteIdForUserId();
    };

  return (
    <div className={"PublisherInformations ShadowContainer "+(props.childClass ? props.childClass : "")}>
        <h1 className="RegularTitle FlexRow AlignCenter">
            Publisher's informations
            {userInformations.user_suspended ?
                <div className={"LabelCard BBlack ML10"} style={{fontSize:"20px"}}>
                    <i className={"fa-solid fa-ban"}></i>&nbsp;
                    Suspended user {userInformations.user_suspended_date ? "(" + userInformations.user_suspended_date + ")" : ""}
                </div> : ""
            }
        </h1>
        <div className="FlexRow Width100">
            <div className="FlexCol Width75 ">
                <h3>Global informations</h3>
                <div className="FlexRow WrapContent Gap15">
                    <div className="FlexCol Width20">
                        <h5 className="RegularTitle MT0 MB5">Firstname</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_firstname ?? (l ?? '/')}</p>
                    </div>
                    <div className="FlexCol Width20">
                        <h5 className="RegularTitle MT0 MB5">Lastname</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_lastname ?? (l ?? '/')}</p>
                    </div>
                    <div className="FlexCol Width30">
                        <h5 className="RegularTitle MT0 MB5">Email</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_mail ?? (l ?? '/')}</p>
                    </div>
                    <div className="FlexCol Width20">
                        <h5 className="RegularTitle MT0 MB5">Phone</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_phone ?? (l ?? '/')}</p>
                    </div>
                    <div className="FlexCol Width20">
                        <h5 className="RegularTitle MT0 MB5">Sponsor</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">
                            {userInformations.user_id_parrain ?
                                <a className="ActionLink"
                                   href={"publisher/" + userInformations.user_id_parrain}>{userInformations.user_id_parrain}</a>
                                : (l ?? '/')}
                        </p>
                    </div>
                    <div className="Width100"></div>
                    <div className="FlexCol Width20">
                        <h5 className="RegularTitle MT0 MB5">City</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_city ?? (l ?? '/')}</p>
                    </div>
                    <div className="FlexCol Width20">
                        <h5 className="RegularTitle MT0 MB5">Postal Code</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_zip ?? (l ?? '/')}</p>
                    </div>
                    <div className="FlexCol Width20">
                        <h5 className="RegularTitle MT0 MB5">Country</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_country ?? (l ?? '/')}</p>
                    </div>
                    <div className="FlexCol Width70">
                        <h5 className="RegularTitle MT0 MB5">Address</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_adress ?? (l ?? '/')}</p>
                    </div>
                </div>
                <h3>User's company</h3>
                <div className="FlexRow WrapContent Gap15">
                    <div className={"FlexCol Width70"}>
                        <h5 className="RegularTitle MT0 MB5">Company</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_entreprise ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width30"}>
                        <h5 className="RegularTitle MT0 MB5">Siren</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_siren ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width30"}>
                        <h5 className="RegularTitle MT0 MB5">TVA</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_tva ?? (l ?? '/')}</p>
                    </div>
                </div>
                <h3>Users - Billing details</h3>
                <div className="FlexRow WrapContent Gap15">
                    <div className={"FlexCol Width50"}>
                        <h5 className="RegularTitle MT0 MB5">Paypal email</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_paypal_mail ?? (l ?? '/')}</p>
                    </div>
                    <div className="FlexCol Width100"></div>
                    <div className={"FlexCol Width40"}>
                        <h5 className="RegularTitle MT0 MB5">Name</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_bank_name ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width40"}>
                        <h5 className="RegularTitle MT0 MB5">Bank Name</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_bank_namebank ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width40"}>
                        <h5 className="RegularTitle MT0 MB5">IBAN</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_bank_iban ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width40"}>
                        <h5 className="RegularTitle MT0 MB5">SWIFT</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_bank_bic ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width40"}>
                        <h5 className="RegularTitle MT0 MB5">City</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_bank_citybank ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width40"}>
                        <h5 className="RegularTitle MT0 MB5">Postal Code</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_bank_zipbank ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width30"}>
                        <h5 className="RegularTitle MT0 MB5">Country</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_country_bankbank ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width60" + (!userInformations.user_paypal_mail && l ? " Hide" : "")}>
                        <h5 className="RegularTitle MT0 MB5">Address</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_bank_adressbank ?? (l ?? '/')}</p>
                    </div>

                    <div className={"FlexCol Width20"}>
                        <h5 className="RegularTitle MT0 MB5">Intermediary Name</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_bank_name ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width20"}>
                        <h5 className="RegularTitle MT0 MB5">Intermediary IBAN</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_bank_iban_inter ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width20"}>
                        <h5 className="RegularTitle MT0 MB5">Intermediary SWIFT</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_bank_bic_inter ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width20"}>
                        <h5 className="RegularTitle MT0 MB5">Intermediary Bank Name</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_bank_namebank_inter ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width20"}>
                        <h5 className="RegularTitle MT0 MB5">Intermediary City</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_bank_citybank_inter ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width20"}>
                        <h5 className="RegularTitle MT0 MB5">Intermediary Postal Code</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_bank_zipbank_inter ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width20"}>
                        <h5 className="RegularTitle MT0 MB5">Intermediary Country</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_country_bank_inter ?? (l ?? '/')}</p>
                    </div>
                    <div className={"FlexCol Width70" + (!userInformations.user_paypal_mail && l ? " Hide" : "")}>
                        <h5 className="RegularTitle MT0 MB5">Intermediary Address</h5>
                        <p className="LiteLoad M0 LightText ReadOnlyText P5">{userInformations.user_bank_adressbank ?? (l ?? '/')}</p>
                    </div>
                </div>
                <h3>Users - Documents</h3>
                <div className="FlexRow WrapContent Gap15">
                    <div className={"FlexCol Width50"}>
                        <h5 className="RegularTitle MT0 MB5">Document bank</h5>
                        {userInformations.user_bank_document && userInformations.user_bank_document !== "/" ? (
                            <button className="BRed TWhite ActionButton Width30" onClick={() => {
                                openViewImage(userInformations.user_bank_document)
                            }}>Click to view document</button>
                        ) : <p className="LightText MT0">No document</p>}
                    </div>
                    <div className={"FlexCol Width50"}>
                        <h5 className="RegularTitle MT0 MB5">Document bank Intermediary</h5>
                        {userInformations.user_bank_document_inter && userInformations.user_bank_document_inter !== "/" ? (
                            <button className="BRed TWhite ActionButton Width30" onClick={() => {
                                openViewImage(userInformations.user_bank_document_inter)
                            }}>Click to view document</button>
                        ) : <p className="LightText MT0">No document</p>}
                    </div>
                </div>
            </div>
            <div className="VerticalSeparator ML5 MR5"></div>
            <div className="FlexRow WrapContent Gap15 Width25 HFitContent">
                <button className="BGreen ActionButton TWhite" onClick={() => {
                    handleMoneyboxUserIdSubmit();
                }}>
                    <i className="fa-solid fa-piggy-bank"></i>
                </button>
                {userInformations.user_suspended ?
                    <LabelCardComponent childClass="ML5" icon="fa-solid fa-ban" color={"BBlack"}
                                        text={"Suspended user " + (userInformations.user_suspended_date ? "(" + userInformations.user_suspended_date + ")" : "")}/> : ""}
                {userInformations.user_premium === "1" ?
                    <LabelCardComponent childClass="ML5" icon="fa-solid fa-star" color={"BYellow"}
                                        text={"User Premium"}/> : ""}
                {userInformations.user_phone_checked === "1" ?
                    <LabelCardComponent childClass="ML5" icon="fa-solid fa-mobile-screen" color={"BRed"}
                                        text={"Phone verified"}/> : ""}
                {userInformations.user_currency ?
                    <LabelCardComponent childClass="ML5" icon="fa-solid fa-coins" color={"BRed"}
                                        text={"Currency " + userInformations.user_currency}/> : ""}
                <LabelCardComponent childClass="ML5" icon="fa-regular fa-credit-card" color={"BRed"}
                                    text={"Net " + (userInformations.user_net30 === 1 ? "30" : "60")}/>
            </div>
        </div>
    </div>
  );
}

export default PublisherInformations;
  